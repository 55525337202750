<template>
  <n-grid cols="1 s:10" responsive="screen">
    <n-gi span="8" offset="0 s:1">
      <n-card :bordered="false">
        <template #default>
          <div class="disclaimer px-2">
            <markdown :source="source" />
          </div>
        </template>
        <template #footer><slot name="footer"></slot></template>
      </n-card>
    </n-gi>
  </n-grid>
</template>
<script>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { NCard, NGrid, NGi } from "naive-ui";
import Markdown from "vue3-markdown-it";
export default {
  name: "MarkdownViewer",
  components: { NCard, NGrid, NGi, Markdown },
  props: {
    fullscreen: {
      type: Boolean,
      defaul: false,
    },
    source: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    onMounted(async () => {
      if (props.fullscreen) {
        await store.dispatch("app/hideHeader");
        await store.dispatch("app/hideSearchBar");
      }
    });
    onUnmounted(async () => {
      if (props.fullscreen) {
        await store.dispatch("app/showHeader");
        await store.dispatch("app/showSearchBar");
      }
    });
  },
};
</script>
<style>
.disclaimer {
  background-color: #f0f0f0;
  text-align: justify;
}
</style>
